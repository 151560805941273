import NextProgress from 'next-progress';
import React, { useEffect } from 'react';
import Redis from 'ioredis';
import Router, { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import fs from 'fs';
import nProgress from 'nprogress';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { WebPageJsonLd } from 'next-seo';
import { 
  defaultInternationalization, 
  defaultLanguage, 
  Languages, 
  notCurrentAvailableInternationalization, 
  notCurrentAvailableInternationalizationPROD 
} from 'corporate-types';
import { capitalizeFirstLetter, internationalizationToLanguages, isPrerenderCrawler, mergeObjects, multiCompanySafeEnv } from 'corporate-utils';
import { Logger } from 'corporate-utils';

// Services
import { sectionComponents } from '../sections/sections';

// Models
import { GlobalResponse, MegamenuLink, StrapiComponentProps } from '../models/domain/interfaces';
import { Pages } from '../models/domain/enum';
import { LocalizedPaths } from '../models/domain/type';

// Utils
import { analyzeSlug, fromNextContextToStrapiPageDataParams } from '../utils/slug';
import { getAllTranslations, getLocalizedPaths } from '../utils/localize';
import { getGlobalData, getPageData } from '../utils/api';
import { getRedirectUrlFromRedis, loadRedirectInRedis } from '../utils/redirect';
import { fixRewriteLink } from '../utils/rewrite';
import { getStats, RedisSingletonInstance } from '../utils/redis';
import { mapToWebPageJsonLd } from '../utils/schemas';
import { shrinkMegaMenuLinkAfter, shrinkMegaMenuLinkBefore } from '../utils/shrink';
import { getCompany } from '../utils/company';
import { canonicalPropsGenerator, getPaginationData, getPrevNextPaginationPageNumbers, hrefGenerator, prevNextLinkGenerator } from '../utils/seo';

// Server side props
import { getServerSideProps as SliderProductsGetServerSideProps } from '../sections/home/slider-products/SliderProducts.server';
import { getServerSideProps as ProductPresentationGetServerSideProps } from '../sections/home/product-presentation/productPresentation.server';

// Theme
import { useTheme } from '../theme';

// Sections
const Sections = dynamic(() => import('../sections/sections'));

// Components
const Layout = dynamic(() => import('../components/layout/Layout'));
const Seo = dynamic(() => import('../components/seo/Seo'));

// The file is called [[...slug]].js because we're using Next's
// optional catch all routes feature. See the related docs:
// https://nextjs.org/docs/routing/dynamic-routes#optional-catch-all-routes


const DynamicPage: React.FC<any> = ({
  sections,
  metadata,
  preview,
  global,
  pageContext,
  translations,
  slugAnalyzed,
  userContext,
  isPrerenderRequest
}: any) => { // TODO: missing type!  

  const theme = useTheme();
  const router = useRouter();
  const company = getCompany();
  useEffect(() => {

    const done = () => {
      setTimeout(() => {
        nProgress.done();
      }, 500); 
    };

    Router.events.on('routeChangeComplete', done);
    Router.events.on('routeChangeError', done);

    return () => {
      Router.events.off('routeChangeComplete', done);
      Router.events.off('routeChangeError', done);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      nProgress.done();
    }, 500); 
    
  }, [router.asPath]);

  // Loading screen (only possible in preview mode)
  if (router.isFallback) {
    return <div className="container">Loading...</div>; // TODO: create skeleton!
  }

  // Merge default site SEO settings with page specific SEO settings
  if (metadata?.shareImage?.data === null) {
    delete metadata?.shareImage;
  }
  const metadataWithDefaults = {
    ...(global?.attributes?.metadata || {}),
    ...metadata,
    locale: router?.locale || defaultInternationalization,
    locales: router?.locales || [defaultInternationalization],
    slugAnalyzed,
    socials: {
      twitter: global?.attributes?.twitterUsername,
      youtube: global?.attributes?.youtubeUsername,
      linkedin: global?.attributes?.linkedinUsername,
      facebook: global?.attributes?.facebookUsername,
    },
    
  };

  const currentRouteWithoutQueryParams = router.asPath?.split?.('?')?.[0];

  const pagination = getPaginationData(sections);

  if(pagination){
    const {prevPageNumber, nextPageNumber} = getPrevNextPaginationPageNumbers(pagination);
    if(prevPageNumber){
      metadataWithDefaults.prevPageLink = prevNextLinkGenerator(currentRouteWithoutQueryParams, router.query, prevPageNumber);
    }
    if(nextPageNumber){
      metadataWithDefaults.nextPageLink = prevNextLinkGenerator(currentRouteWithoutQueryParams, router.query, nextPageNumber);
    }
  }

  return (
    <>
      {company && <NextProgress
        color={theme?.state?.theme?.colors?.primaryException?.[company]}
        delay={300}
        options={{ showSpinner: false }}
      />}
      <Layout
        isPrerenderRequest={isPrerenderRequest}
        global={global}
        pageContext={pageContext}
        translations={translations}
        userContext={userContext}
        productName={metadata?.productSchema ? metadata.productSchema?.productName || '' : ''}
      >
        <Seo metadata={metadataWithDefaults} />
        {sections && <Sections
          sections={sections}
          isPrerenderRequest={isPrerenderRequest}
          preview={preview}
          translations={translations}
          privacyPolicy={{
            text: global?.attributes?.footer?.privacyText,
            link: global?.attributes?.footer?.privacyLink
          }}
          metadata={{
            ...metadataWithDefaults,
            logo: global?.attributes?.header.logo
          }}
          support={{
            newsAndEventsMerged: global?.attributes?.newsAndEventsMerged
          }}
          userContext={userContext}
        />}
        <WebPageJsonLd
          {...mapToWebPageJsonLd(
            metadataWithDefaults.metaDescription,
            translations
          )}
        />
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => { // TODO: missing type!

  const DEBUG = multiCompanySafeEnv({key:'CUSTOM_LAMBDA_LOGGER_LEVEL', fallbackValue: 'WARN'});
  const {
    params,
    query,
    locale,
    locales,
    defaultLocale,
    preview,
    resolvedUrl,
    res,
    req
  } = context;

  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx context', context);

  const country = req.headers['cloudfront-viewer-country'] || 'EN';
  const userAgent = req.headers['user-agent'];
  let isPrerenderRequest = false;

  if (userAgent) {
    isPrerenderRequest = isPrerenderCrawler(userAgent);
  }

  const nextRequestMeta = (req as any)?.[Reflect.ownKeys(req)?.find?.(
    (s) => String(s) === 'Symbol(NextRequestMeta)'
  ) || '' ];

  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx nextRequestMeta.__NEXT_INIT_URL', nextRequestMeta.__NEXT_INIT_URL); // https://stackoverflow.com/questions/71297590/how-to-get-the-full-url-from-the-api-route-in-nextjs

  const preRewriteUrl = (res as any)?.[Reflect.ownKeys(res)?.find?.(
    (s) => String(s) === 'Symbol(kOutHeaders)'
  ) || '' ]?.['x-rewrite-to']?.[1];
  
  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx nextRequestMeta.preRewriteUrl', preRewriteUrl);


  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx headers', req.headers);

  const originalUrlSplitted: string[] = nextRequestMeta?.__NEXT_INIT_URL?.split?.(req.headers.host); // .[1] || null;
  let originalUrl;

  if(originalUrlSplitted.length === 2) {
    originalUrl = originalUrlSplitted[1] || null;
  } else {
    // lenght === 1
    originalUrl = nextRequestMeta?.__NEXT_INIT_URL?.split?.('http://localhost:3000')?.[1] || null;
  }

  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx originalUrl', originalUrl);

  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx locale', locale);
  Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx NEXT_PUBLIC_ENV', process.env.NEXT_PUBLIC_ENV);

  if(locale && 
    (process.env.NEXT_PUBLIC_ENV === 'PROD' ? Object.values(
      notCurrentAvailableInternationalizationPROD
    ).indexOf(locale) !== -1 : Object.values(
      notCurrentAvailableInternationalization
    ).indexOf(locale) !== -1)
  ) {
    Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx condition true reset cache');

    res.setHeader(
      'Cache-Control',
      'no-cache, max-age=0, must-revalidate'
    );
    return {
      notFound: true
    };
  }

  const path = `${__dirname}/../../../public${resolvedUrl}`;

  const startTs = Date.now();
  let delta = Date.now();
  let nowTs;
  const showMessage: any[] = [`${resolvedUrl} Start: ${new Date(startTs)} ${startTs}`];

  if ((resolvedUrl === '/' || !fs.existsSync(path)) && !resolvedUrl.includes('/api/api/') && !!locale) {

    const redis = RedisSingletonInstance;

    let serverSideProps: GetServerSideProps | null = null;
    let serverSidePropsCache;

    const reedisStats = await getStats(redis as Redis);    

    Logger.info(`${resolvedUrl} - redis memory status:`, JSON.stringify(reedisStats, null, 2));

    if(reedisStats.limitReached) {
      Logger.error('redis memory full!');
    }

    try {
      serverSidePropsCache = locale !== 'default' && resolvedUrl && await (redis as Redis).get(process.env.NEXT_PUBLIC_COMPANY + ':' + encodeURI(`serverSidePropsCache-${country}-${originalUrl}`));
    } catch (error) {
      Logger.error('packages/corporate-ui/src/pages/[[...slug]].tsx error', error);
    }

    if (serverSidePropsCache) {
      serverSideProps = JSON.parse((serverSidePropsCache as string || '{}'));
      return serverSideProps;
    } else {
      let cacheGlobalLocale;
      let globalLocale: GlobalResponse | null = null;
  
      try {
        cacheGlobalLocale = locale && await (redis as Redis).get(process.env.NEXT_PUBLIC_COMPANY + ':' + encodeURI(`globalLocale-${locale}`));
      } catch (error) {
        Logger.error('packages/corporate-ui/src/pages/[[...slug]].tsx error', error);
      }
  
      nowTs = Date.now();
      showMessage.push(`${resolvedUrl} After checking cacheGlobalLocale ${!!cacheGlobalLocale} ${new Date(nowTs)} ${nowTs - delta}ms`);
      delta = Date.now();
  
      if (cacheGlobalLocale) {
        globalLocale = JSON.parse(cacheGlobalLocale);
      } else {
        try {
          globalLocale = await getGlobalData(locale || defaultInternationalization, resolvedUrl);
          globalLocale.attributes.header.megamenuLinks = shrinkMegaMenuLinkBefore(
            globalLocale?.attributes?.header?.megamenuLinks || []
          ) as MegamenuLink[];
          !reedisStats.limitReached && locale && (redis as Redis).set(process.env.NEXT_PUBLIC_COMPANY + ':' + encodeURI(`globalLocale-${locale}`), JSON.stringify(globalLocale), 'EX', parseInt(process.env.REDIS_TTL || '60'));
        } catch (e) {
          Logger.error(e);
          res.setHeader(
            'Cache-Control',
            'no-cache, max-age=0, must-revalidate'
          );
          return {
            redirect: {
              destination: '/502',
              permanent: false
            }
          };
        }
      }   

      const prodsAltLocGoToHomeCurrentLocale = globalLocale?.attributes?.prodsAltLocGoToHome;
  
      nowTs = Date.now();
      showMessage.push(`${resolvedUrl} After downloading getGlobalData ${!!cacheGlobalLocale} ${new Date(nowTs)} ${nowTs - delta}ms`);
      delta = Date.now();
  
      const allTranslations = await getAllTranslations();

      nowTs = Date.now();
      showMessage.push(`${resolvedUrl} After getting getAllTranslations ${new Date(nowTs)} ${nowTs - delta}ms`);
      delta = Date.now();
  
      const translations = {...allTranslations[internationalizationToLanguages(locale || defaultInternationalization, resolvedUrl) || defaultLanguage]};
  
      const fixedPageUrls = globalLocale?.attributes?.fixedPageUrls || {};
      const fixedPageUrlsParsed: {
        [key: string]: any;
      } = {};
  
      Object.keys(fixedPageUrls).forEach((key) => {
        if(fixedPageUrls[key] !== null && fixedPageUrls[key] !== undefined && fixedPageUrls[key] !== '') {
          fixedPageUrlsParsed[key] = fixedPageUrls[key];
        }
      });
  
      translations.urls = {...mergeObjects(translations?.urls, fixedPageUrlsParsed)};

      const prodsAltLocGoToHome: { 
        locale: Languages;
        prodsAltLocGoToHome: boolean;
      }[] = [];
        
      if(globalLocale?.attributes?.localizations?.data) {
        globalLocale.attributes.localizations.data.map((localization) => {
          const localizedObject = localization?.attributes;
          if(localizedObject) {
            const key = internationalizationToLanguages(localizedObject?.locale || defaultInternationalization, resolvedUrl);
            if(localizedObject?.locale) {
              prodsAltLocGoToHome.push({
                locale: localizedObject?.locale,
                prodsAltLocGoToHome: localizedObject.prodsAltLocGoToHome
              });
            }
  
            if(key && allTranslations?.[key]?.urls) {
  
              const localizedObjectParsed: {
                [key: string]: any;
              } = {};
  
              Object.keys(localizedObject?.fixedPageUrls).forEach((key) => {
                if(localizedObject?.fixedPageUrls[key] !== null && localizedObject?.fixedPageUrls[key] !== undefined && localizedObject?.fixedPageUrls[key] !== '') {
                  localizedObjectParsed[key] = localizedObject?.fixedPageUrls[key];
                }
              });
  
              allTranslations[key].urls = mergeObjects(
                allTranslations[key].urls, 
                localizedObjectParsed
              );
            }
          }
        });
      }
  
      nowTs = Date.now();
      showMessage.push(`${resolvedUrl} After manipulating translations ${new Date(nowTs)} ${nowTs - delta}ms`);
      delta = Date.now();
      
      let pageData: any = null;

      try {
        // Logic:
        // 1. try to get page from CMS;
        //  1.1. page exist. It is necessary to analyze the slug to detect the page type:
        //    1.1.1 tha page is news and events/single news/event: download the news;
        //    1.1.2 the page is single news: download page info;
        //    1.1.3 the page is event: download page info;
        //    1.1.4 otherwise render page with sections.
        //  1.2 the page doesn't exist. It is necessary to analyze the slug to detect the page type:
        //    1.2.1 the page has slug length equal to 2: it is the category page;
        //    1.2.2 the page has slug length equal to 4: it is the product page;
        //    1.2.3 page has no type: 404 page;
        // 2. there is an error: 404 page.
  
        const { slug: slugFromParams } = params || {};
  
        const pageDataParams = await fromNextContextToStrapiPageDataParams({
          params,
          locale,
          preview
        } as GetServerSidePropsContext);
        pageData = await getPageData(pageDataParams);
  
        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} After getPageData ${new Date(nowTs)} ${nowTs - delta}ms`);
        delta = Date.now();
  
        const slugAnalyzed = await analyzeSlug(
          slugFromParams,
          locale || defaultInternationalization,
          globalLocale?.attributes?.header?.megamenuLinks || [],
          pageData,
          translations,
          query,
          globalLocale as GlobalResponse,
          preRewriteUrl
        );
  
        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} After analyzeSlug ${new Date(nowTs)} ${nowTs - delta}ms`);
        delta = Date.now();
  
        Logger.info('slugAnalyzed', slugAnalyzed);
        
        switch (slugAnalyzed.type) {  
  
          case Pages.SECTOR: {
            const { sectorPageDataGenerator } = (await import('../services/pageDataGenerator/sector'));
  
            pageData = await sectorPageDataGenerator(
              slugAnalyzed,
              globalLocale,
              query,
              translations,
              locale
            ) ?? pageData;
            break;
          }
  
          case Pages.CATEGORY_FATHER: {
            const { categoryFatherPageDataGenerator } = (await import('../services/pageDataGenerator/categoryFather'));
  
            pageData = await categoryFatherPageDataGenerator(
              slugAnalyzed,
              globalLocale,
              query,
              translations,
              locale,
            ) ?? pageData;
            break;
          }
  
          case Pages.CATEGORY: {
            const { categoryPageDataGenerator } = (await import('../services/pageDataGenerator/category'));
            pageData = await categoryPageDataGenerator(
              slugAnalyzed,
              globalLocale,
              query,
              translations,
              locale,
            ) ?? pageData;
            break;
          }
  
          case Pages.PRODUCT: {
            const { productPageDataGenerator } = (await import('../services/pageDataGenerator/product'));
  
            pageData = await productPageDataGenerator(
              slugFromParams as string [], 
              slugAnalyzed, 
              query.page as string, 
              locale as string, 
              translations, 
              globalLocale as any
            ) ?? pageData;
            break;
          }
  
          case Pages.EVENT: {
            const { eventPageDataGenerator } = (await import('../services/pageDataGenerator/event'));
  
            pageData = await eventPageDataGenerator(
              slugAnalyzed,
              translations,
              allTranslations,
              locale,
              globalLocale as any
            ) ?? pageData;
            break;
          }
  
          case Pages.NEWS: {
            const { newsPageDataGenerator } = (await import('../services/pageDataGenerator/news'));
  
            pageData = await newsPageDataGenerator(
              slugAnalyzed,
              translations,
              allTranslations,
              locale,
              globalLocale as any
            ) ?? pageData;
            break;
          }
  
          case Pages.WORKSHOP: {       
            const { workshopPageDataGenerator } = (await import('../services/pageDataGenerator/workshop'));
  
            pageData = await workshopPageDataGenerator(
              slugAnalyzed,
              locale || defaultInternationalization,
              translations,
              allTranslations
            ) ?? pageData;
            break;
          }
  
          case Pages.SEARCH: {
            const { searchPageDataGenerator } = (await import('../services/pageDataGenerator/search'));
  
            pageData = await searchPageDataGenerator(
              query as any,
              locale || defaultInternationalization,
              translations,
              globalLocale?.attributes?.header?.megamenuLinks || [],
              allTranslations,
            ) ?? pageData;
            break;
          }
  
          case Pages.ERROR_NOT_FOUND: {
            const { errorInternalPageDataGenerator } = (await import('../services/pageDataGenerator/errorInternal'));
  
            pageData = await errorInternalPageDataGenerator(
              slugAnalyzed,
              translations,
              locale
            ) ?? pageData;
            break;
          }
  
          case Pages.ERROR_INTERNAL: {
            const { errorNotFoundPageDataGenerator } = (await import('../services/pageDataGenerator/errorNotFound'));
  
            pageData = await errorNotFoundPageDataGenerator(
              slugAnalyzed,
              translations,
              locale
            ) ?? pageData;
            break;
          }
  
          default: {
            if(pageData !== null && pageData?.attributes?.contentSections && slugAnalyzed.type !== Pages.HOME) {
              pageData.attributes.contentSections.unshift({
                __component: 'componenti.breadcrumb',
                breadCrumb: {
                  links: [
                    {
                      href: `/${locale}`,
                      name: translations?.urls?.home,
                    }
                  ],
                  pageActive: {
                    name: capitalizeFirstLetter(pageData?.attributes?.shortName),
                  }
                },
              },);
            } 
            break;
          }
        }
  
        (globalLocale as GlobalResponse).attributes.header.megamenuLinks = shrinkMegaMenuLinkAfter(
          globalLocale?.attributes?.header?.megamenuLinks || [], 
          locale || defaultInternationalization,
          slugAnalyzed
        ) as MegamenuLink[];

        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} After getting specific page ${slugAnalyzed.type} ${new Date(nowTs)} ${nowTs - delta}ms`);
        delta = Date.now();
        
        const sectionServerSideProps: any = {
          'home.slider-products': {
            getServerSideProps: SliderProductsGetServerSideProps,
          },
          'home.product-presentation': {
            getServerSideProps: ProductPresentationGetServerSideProps,
          }
        };
  
        if(pageData?.attributes?.contentSections) {
          await Promise.all(
            pageData?.attributes?.contentSections?.map?.(async (section: StrapiComponentProps, index: number) => {
              // ATTENTION: section is update as ref
              await (sectionServerSideProps[section.__component] || sectionComponents?.[section.__component])?.getServerSideProps?.(
                pageData?.attributes?.contentSections?.[index], 
                translations, 
                slugAnalyzed,
                globalLocale
              );
            })
          );
        }

        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} After getting server side props of components ${new Date(nowTs)} ${nowTs - delta}ms`);
        delta = Date.now();
  
        if (pageData === null) {  
          
          if(originalUrl === null) {
            res.setHeader(
              'Cache-Control',
              'no-cache, max-age=0, must-revalidate'
            ); 
            return {
              notFound: true
            };
          }
          
          const areRedirectsLoadedInRedis = (await (redis as Redis).get(`${process.env.NEXT_PUBLIC_COMPANY}/redirects-are-loaded`)) === 'true';

          Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx areRedirectsLoadedInRedis', areRedirectsLoadedInRedis);

          if(!areRedirectsLoadedInRedis) {
            try {
              const resultOfLoading = await loadRedirectInRedis(); 
              Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx result of loading redirects in redis', resultOfLoading);
            } catch (error) {
              Logger.error('packages/corporate-ui/src/pages/[[...slug]].tsx error of loading redirects in redis', error);
            }
          } 

          const originalUrlNoQueryString = originalUrl?.split('?')?.[0] || originalUrl;
          const querystringFromOriginalUrl = originalUrl?.split('?')?.[1] || '';

          Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx original link and query params', {
            originalUrlNoQueryString,
            querystringFromOriginalUrl
          });

          const redirectUrl: string | null = await getRedirectUrlFromRedis({uri: originalUrlNoQueryString, querystring: querystringFromOriginalUrl});

          Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx redirect-url', redirectUrl);

          if(redirectUrl !== null) {
            return {
              redirect: {
                destination: redirectUrl,
                permanent: true,
              },
            };
          } else {
            res.setHeader(
              'Cache-Control',
              'no-cache, max-age=0, must-revalidate'
            ); 
            return {
              notFound: true
            };
          }          
        }
  
        // We have the required page data, pass it to the page component
        const { contentSections, metadata, localizations, slug } =
          pageData.attributes || {};
  
        const pageContext = {
          locale,
          locales,
          defaultLocale,
          slug,
          localizations,
        };
  
        const localizedPaths: LocalizedPaths = getLocalizedPaths(
          pageContext, 
          !!prodsAltLocGoToHomeCurrentLocale,
          prodsAltLocGoToHome,
          slugAnalyzed.type
        );
  
        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} After getLocalizedPaths ${new Date(nowTs)} ${nowTs - delta}ms`);
        delta = Date.now();
  
        Logger.info('localizedPaths', localizedPaths);

        Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx query', query);

        let newProps = {
          props: {
            key: `${Math.floor(Math.random() * 99999999999) + Date.now()}_${locale}`,
            preview,
            sections: contentSections,
            metadata,
            global: globalLocale,
            translations,
            isPrerenderRequest,
            userContext: {
              country
            },
            pageContext: {
              ...pageContext,
              localizedPaths,
            },
            languageAlternates: hrefGenerator(
              localizedPaths,
              {
                asPath: resolvedUrl,
                query
              },
              translations
            ),
            canonical: canonicalPropsGenerator(query, translations, locale || defaultInternationalization)
          }
        };

        Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx canonical before', newProps.props.canonical);
        Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx languageAlternates before', newProps.props.languageAlternates);

        
        newProps = await fixRewriteLink(newProps, locale);

        Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx canonical after', newProps.props.canonical);
        Logger.info('packages/corporate-ui/src/pages/[[...slug]].tsx languageAlternates after', newProps.props.languageAlternates);

        
        res.setHeader(
          'Cache-Control',
          'public, max-age=86400, must-revalidate'
        );

        const cleanEmpty : any = (obj: object) => {
          if (Array.isArray(obj)) { 
            return obj
              .map(v => (v && typeof v === 'object') ? cleanEmpty(v) : v)
              .filter(v => !(v == null || v == undefined)); 
          } else { 
            return Object.entries(obj)
              .map(([k, v]) => [k, v && typeof v === 'object' ? cleanEmpty(v) : v])
              .reduce((a, [k, v]) => ((v == null || v == undefined || k === 'createdAt' || k === 'updatedAt') ? a : ((a[k])=v, a)), {} as any);
          } 
        };

        const newPropsDefined = cleanEmpty(newProps);

        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} After cleaning props ${new Date(nowTs)} ${nowTs - delta}ms`);
        delta = Date.now();

        if(slugAnalyzed?.type !== Pages.SEARCH && !reedisStats.limitReached && locale && locale !== 'default' && resolvedUrl) {
          (redis as Redis).set(process.env.NEXT_PUBLIC_COMPANY + ':' + encodeURI(`serverSidePropsCache-${country}-${originalUrl}`), JSON.stringify(newPropsDefined), 'EX', parseInt(process.env.REDIS_TTL || '60'));
        } else if(slugAnalyzed?.type !== Pages.SEARCH) {
          Logger.error('packages/corporate-ui/src/pages/[[...slug]].tsx', {
            limitReached: !reedisStats.limitReached,
            resolvedUrl,
            locale
          }); 
        }

        nowTs = Date.now();
        showMessage.push(`${resolvedUrl} End ${new Date(nowTs)} - ${nowTs - startTs}ms`);
  
        if(DEBUG || nowTs - startTs > 500) {
          Logger.error(showMessage);
        }

  
        return JSON.parse(JSON.stringify(newPropsDefined));
      } catch (e) {
        Logger.error('ERROR', resolvedUrl, JSON.stringify(e), e);
        console?.trace?.();
        res.setHeader(
          'Cache-Control',
          'no-cache, max-age=0, must-revalidate'
        );
        return {
          redirect: {
            destination: '/500',
            permanent: false,
          },
        };
      }
    }
  } else {
    res.setHeader(
      'Cache-Control',
      'no-cache, max-age=0, must-revalidate'
    );
    return {
      redirect: {
        destination: '/307',
        permanent: false,
      },
    };
  }
};

export default DynamicPage;